@import './variables.scss';
// Small devices
@mixin sm {
	@media (min-width: #{$screen-sm-min}) {
			@content;
	}
}

// Medium devices
@mixin md {
	@media (min-width: #{$screen-md-min}) {
			@content;
	}
}

// Large devices
@mixin lg {
	@media (min-width: #{$screen-lg-min}) {
			@content;
	}
}

// Extra large devices
@mixin xl {
	@media (min-width: #{$screen-xl-min}) {
			@content;
	}
}

@mixin customBP($width){
	@media (min-width: #{$width}px) {
		@content;
	}	
}

@mixin reset-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

@mixin font-size($size){
	//tiny, ex-small, small, medium, large, ex-large
	@if($size == 'tiny'){
		font-size: nth($font-sizes, 1) * 1rem;
	}
	@if($size == 'ex-sml'){
		font-size: nth($font-sizes, 2) * 1rem;
	}
	@if($size == 'sml'){
		font-size: nth($font-sizes, 3) * 1rem;
	}	
	@if($size == 'md'){
		font-size: nth($font-sizes, 4) * 1rem;
	}		
	@if($size == 'lrg'){
		font-size: nth($font-sizes, 5) * 1rem;
	}			
	@if($size == 'ex-lrg'){
		font-size: nth($font-sizes, 6) * 1rem;
	}				
}

@mixin p-trbl($t, $r, $b, $l){
	padding: $t * $pad-unit $r * $pad-unit $b * $pad-unit $l * $pad-unit;
}

@mixin p-tr($t, $r){
	padding: $t * $pad-unit $r * $pad-unit;
}

@mixin m-trbl($t, $r, $b, $l){
	margin: $t * $margin-uni $r * $margin-uni $b * $margin-uni $l * $margin-uni;
}
