@import 'variables.scss';

.ag {
  &-grid {
    &-cell {
      &-v-algn {
        &-cntr {
          display: flex;
          align-items: center;
        }
      }
    }
  }

  &-cell {
      &-wrap-text {
          line-height: 1.3rem;
          word-break: break-word;
          padding-top : .6rem;
      }
  }

  &-row {
    &.disabled {
      opacity: .65; //override disabled.scss for better alignment with Ag Grid styles
      background-color: lighten($clr-black, 90%); //add bg color for disabled row
    }
  }
}


  .pinned {
    //matches the header color of our current styled grids
    background-color: #f8f8f8;
  }

  .ag-root-wrapper {
    overflow: visible;
  }

  .ag-row {
    overflow: visible;
  }

  .ag-row-level-1 {
    background-color: #e7e7e7;
    padding: 1rem;

    .ag-header {
      background-color: #d4d4d4;

      &-cell-text {
        color: #000;
        font-weight: normal;
      }
    }

    .ag-icon-menu::before, .ag-icon-asc::before, .ag-icon-desc::before {
      color: #000;
    }
    //.ag-header-cell-resize::after {
    //  background-color: darken(#fff, 20%);
    //}
  }

  .ag-theme-alpine.scrolling-level-1 {
    .ag-row-level-1 {
      overflow-y: auto;
    }
  }

  .third-party-purposes, .MSDS {
    .ag-overlay-no-rows-center {
      position: relative;
      top: 1.5rem;
    }
  }
