/* PADDING > */

//padding
.p{
	@each $value in $mrgn-pd-values{
		&-#{$value}{
			padding: number(str-replace($value, "-", ".")) * $pad-unit;
		}
	}
}

//padding-trbl
@each $padding in $mrgn-pd-trbl {
	$trbl : getFullFromTRBL($padding);	
	/* padding #{$trbl} */
	.p-#{$padding}{
		@each $value in $mrgn-pd-values{
			&-#{$value}{
				padding-#{$trbl}: number(str-replace($value, "-", ".")) * $pad-unit;
			}
		}
	}
}

/* < PADDING */