@import '../SCSS/variables.scss';
button {
  cursor: pointer;
  @extend .focus-dark-sml;

  &.link {
    border: none;
    background-color: transparent;
    display: inline-block;
  }

  &:disabled {
    opacity: $dsbl-opacity;
    cursor: $dsbl-cursor;
  }
  &.no-wrap{
      white-space:nowrap;
  }
}
