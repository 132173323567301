
.hvr {
  &-bg {
    &-white {
      background-color: $clr-white;
    }

    &-black {
      background-color: $clr-black;
    }

    &-primary {
      background-color: $clr-primary;
    }

    &-secondary {
      background-color: $clr-secondary;
    }

    &-tertiary {
      background-color: $clr-tertiary;
    }
  }
}
