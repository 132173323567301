/* UL > */
ul{
	&.no-bullets{
		list-style-type:none;
	}
	&.no-margin{
		margin:0;
	}
	&.no-padding{
		padding:0;
	}
	&.reset{
		@include reset-list;
	}
}
