/* BORDERS > */


.bdr-clr-white{
	border-color:$clr-white;
	border-width: $bdr-wwdth-px * 1px;
	border-radius: $bdr-radius-rem * 1rem;	
	border-style:$bdr-style;
}
.bdr-clr-black{
	border-color:$clr-black;
	border-width: $bdr-wwdth-px * 1px;
	border-radius: $bdr-radius-rem * 1rem;	
	border-style:$bdr-style;
}

.bdr-clr-red{
	border-color:$clr-red;
	border-width: $bdr-wwdth-px * 1px;
	border-radius: $bdr-radius-rem * 1rem;	
	border-style:$bdr-style;
}

.bdr-clr-tertiary-2{
	border-color:$clr-tertiary-2;
	border-width: $bdr-wwdth-px * 1px;
	border-radius: $bdr-radius-rem * 1rem;	
	border-style:$bdr-style;
}

.bdr-none{
	border:none;
}




/* PRIMARY */
.bdr-clr-p{
	border-color:$clr-primary;
	border-width: $bdr-wwdth-px * 1px;
	border-radius: $bdr-radius-rem * 1rem;	
	border-style:$bdr-style;
}

/* CLR lighten */
@each $percentage in $clr-percentages {
  $percent: percentage(calc($percentage/100));

	.bdr-clr-p-l {
		&-#{$percentage} {
			border-color: lighten($clr-primary, $percent);
			border-width: $bdr-wwdth-px * 1px;
			border-radius: $bdr-radius-rem * 1rem;	
			border-style:$bdr-style;
		}
	}
}
/* darken */
@each $percentage in $clr-percentages {
  $percent: percentage(calc($percentage/100));

	.bdr-clr-p-d {
		&-#{$percentage} {
			border-color: darken($clr-primary, $percent);
			border-width: $bdr-wwdth-px * 1px;
			border-radius: $bdr-radius-rem * 1rem;	
			border-style:$bdr-style;
		}
	}
}


.bdr-t-radius-0{
	border-top-left-radius:0;
	border-top-right-radius:0;

}
/* < BORDERS */
