.cursor{
	&-not-allowed{
		cursor:not-allowed;
	}
	&-help{
		cursor:help;
	}
	&-pointer{
		cursor:pointer;
	}
	&-auto{
		cursor:auto;
	}
}