@import '../SCSS/variables.scss';
@import '../SCSS/mixins.scss';



input[type=checkbox] {
  appearance: none;

  &:before {
    content: "";
    display: inline-block;
    height: 1rem;
    width: 1rem;
    background-color: $chk-rd-bg-clr;
    border: solid $chk-rd-brdr-wdth $chk-rd-brdr-clr;
    line-height: 1rem;
    vertical-align: text-top;
    border-radius: $chk-rd-brdr-radius;
    cursor: pointer;
  }

  &:checked {
    &:before {
      content: "\2713";
      color: $chk-rd-chkd-chk-clr;
      background-color: $chk-rd-chkd-bg-clr;
      border-color: $chk-rd-chkd-brdr-clr;
      font-weight: bold;
      font-size: .7rem;
      text-align: center;
      cursor: pointer;
    }
  }

  &:disabled {

    &:before {
      opacity: $dsbl-opacity;
      cursor: $dsbl-cursor;
    }
  }
}

/* inside Ag Grid */
.ag-cell {
  input[type=checkbox]{
    &:focus {
      outline: solid 2px #2196f3;
      outline-offset: 1px;
    }
  }
}
