/* MARGINS > */

//margin-all
.m{
	@each $value in $mrgn-pd-values{
		&-#{$value}{
			margin: number(str-replace($value, "-", ".")) * $margin-unit;
		}
	}
}

//margin-trbl
@each $margin in $mrgn-pd-trbl {
	$trbl : getFullFromTRBL($margin);	
	/* margin #{$trbl} */
	.m-#{$margin}{
		@each $value in $mrgn-pd-values{
			&-#{$value}{
				margin-#{$trbl}: number(str-replace($value, "-", ".")) * $margin-unit;
			}
		}
	}
}
.m-auto{
	margin:auto;
}
/* < MARGINS */