/* You can add global styles to this file, and also import other style files */
@import 'ag-grid-community/styles/ag-grid.css';
@import 'ag-grid-community/styles/ag-theme-alpine.css';
// Order is important
// bottom imports are applied last and can
// override classes from earlier imports

html, body, app-root {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

//global
@import "./SCSS/normalize.scss";
@import "./SCSS/mixins.scss";
@import "./SCSS/functions.scss";
@import "./SCSS/variables.scss";
@import "./SCSS/gcaptcha.scss";

//specific
@import "./SCSS/a.scss";
@import "./SCSS/ag-grid.scss";
@import "./SCSS/align.scss";
@import "./SCSS/backgrounds.scss";
@import "./SCSS/borders.scss";
@import "./SCSS/buttons.scss";
@import "./SCSS/calcs.scss";
@import "./SCSS/checkbox.scss";
@import "./SCSS/colors.scss";
@import "./SCSS/cursor.scss";
@import "./SCSS/default-container.scss";
@import "./SCSS/disabled.scss";
@import "./SCSS/display.scss";
@import "./SCSS/divider.scss";
@import "./SCSS/focus.scss";
@import "./SCSS/font.scss";
@import "./SCSS/h.scss";
@import "./SCSS/height.scss";
@import "./SCSS/hover.scss";
@import "./SCSS/icons.scss";
@import "./SCSS/images.scss";
@import "./SCSS/inputs.scss";
@import "./SCSS/label.scss";
@import "./SCSS/letter-spacing.scss";

@import "./SCSS/ng-select.scss";
@import "./SCSS/ng-datepicker.scss";
@import "./SCSS/p.scss";

@import "./SCSS/popper.scss";
@import "./SCSS/radio.scss";
@import "./SCSS/select.scss";
@import "./SCSS/sr.scss";
@import "./SCSS/tabs.scss";
@import "./SCSS/transition.scss";
@import "./SCSS/ul.scss";
@import "./SCSS/text-decoration.scss";
@import "./SCSS/text-transform.scss";
@import "./SCSS/textbox.scss";
@import "./SCSS/width.scss";
@import './SCSS/sg.scss';
@import './SCSS/white-space.scss';

@import "./SCSS/margin.scss";
@import "./SCSS/padding.scss";
