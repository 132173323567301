html{
	font-family: $font-family;
}
.font{
	@each $size in $font-sizes {
		$i: index($font-sizes, $size);
		@if($i == 1){
			&-tiny{
				font-size: $size * 1rem;
			}
		}
		@if($i == 2){
			&-ex-sml{
				font-size: $size * 1rem;
			}
		}
		@if($i == 3){
			&-sml{
				font-size: $size * 1rem;
			}
		}
		@if($i == 4){
			&-med{
				font-size: $size * 1rem;
			}
		}
		@if($i == 5){
			&-lrg{
				font-size: $size * 1rem;
			}
		}
		@if($i == 6){
			&-ex-lrg{
				font-size: $size * 1rem;
			}
		}		
	}
	&-bold{
		font-weight:bold;
	}
  &-normal{
      font-weight:normal;
  }
}
