/* DISPLAY > */
.dis{
	&-block{
		display:block;
	}
	&-flex{
		display:flex;
	}
	&-grid{
		display:grid;
	}
	&-inline{
		display:inline;
		&-block{
			display:inline-block;
		}
		&-flex{
			display:inline-flex;
		}
	}
	&-none{
		display:none;
	}
	&-table{
		display:table;
	}
}

/* < DISPLAY */