@import '../SCSS/variables.scss';
@import '../SCSS/mixins.scss';

label {
  color: $clr-tertiary;
  text-transform: uppercase;
  font-weight: bold;
  font-size: .8rem;
  gap: .5rem;
  display: inline-flex;
  align-items: center;

  &.inline {
    display: block;
  }

  &.inline.wht-spc-nowrp {
    white-space: normal;
  }

  &.clr-black {
    color: $clr-black;
  }

  &.block {
    display: grid;
    grid-template-rows: repeat(2, auto);
    gap: 0;

    > input[type=text], ng-select {
      margin-top: $input-label-m-t;
    }
  }

  &.full-width {
    display: flex;

    > * {
      flex-grow: 1;
    }
  }

  &.disabled {
    opacity: $dsbl-opacity;
    cursor: $dsbl-cursor;
  }
}

@include md {
  label {
    &.inline {
      display: inline-flex;
      justify-self: stretch;
      text-align: right;

      &.wht-spc-nowrp {
        white-space: nowrap;
      }
    }
  }
}
