@import '../SCSS/variables.scss';

select {
  &:disabled {
    opacity: $dsbl-opacity;
    cursor: $dsbl-cursor;
  }
}

select.ng-invalid.ng-touched {
  border: 1px solid $clr-red !important;
}