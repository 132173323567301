.bg-white{
	background-color:$clr-white;
}
.bg-black{
	background-color:$clr-black;
}
.bg-trns{
	background-color:transparent;
}

.bg-light-tertiary-2{
	background-color: lighten($clr-tertiary-2, 40%);
}

/* PRIMARY */
.bg-p{
	background-color:$clr-primary;
}

