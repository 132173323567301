/* HEADINGS > */

@for $i from 1 through length($heading-values) {
	$value: nth($heading-values, $i);
	h#{$i}{
			font-weight:normal;
			font-size:$value * 1rem;
	}
	.h#{$i}-size{
		font-size:$value * 1rem;
	}
}

@include md{
	@for $i from 1 through length($heading-values) {
		$value: nth($heading-values, $i);
		h#{$i}{
			font-weight:normal;
			font-size:$value * $heading-md-mulitplier * 1rem;
		}
		.h#{$i}-size{
			font-size:$value * $heading-md-mulitplier * 1rem;
		}		
	}
}

/* custom h colors */
h1{
	color:$clr-tertiary-3;
}
h2, h3, h4, h5{
	color:darken($clr-tertiary-2, 20%);
}
/* < HEADINGS */