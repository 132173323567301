/* temp for style guide layouts - may refactor in the future. Here to allow developers to reuse */

@import 'mixins.scss';

.flex {
  &-hrz, &-vrt {
    display: flex;
    flex-direction: column;
    gap: .5rem;
  }

  &-grp-title {
    margin-bottom: .25rem;
    font-weight: bold;
    font-size: .9rem;
    color: $clr-tertiary;
  }
}

@include sm {
  .flex {
    &-hrz {
      display: flex;
      gap: 1rem;
      align-items: center;
      flex-wrap: wrap;
      flex-direction:row;

      &-chk-container {
        display: flex;
        flex-direction: row;
        gap: 1rem;
      }
    }

    &-vrt {
      flex-wrap: wrap;
      gap:.5rem;
      ui-checkbox {
        display: block;
        margin-bottom: .5rem;

        &:last-of-type {
          margin-bottom: 0;
        }
      }

      &-chk-container {
        display: flex;
        flex-direction: row;
        gap: 1rem;
      }
    }
  }
}

