/* copied from  '@angular/cdk/overlay-prebuilt.css' >  */
/* required by datepicker */
@import "./functions.scss";
.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%
}

.cdk-overlay-container {
  position: fixed;
  z-index: $z-indx-cdk;
}

.cdk-overlay-container:empty {
  display: none
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: $z-indx-cdk;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: $z-indx-cdk;
  display: flex;
  max-width: 100%;
  max-height: 100%
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: $z-indx-cdk;
  pointer-events: auto;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1
}

.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: .6
}

.cdk-overlay-dark-backdrop {
  background: rgba(0,0,0,.32)
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear,opacity 1ms linear;
  visibility: hidden;
  opacity: 1
}

.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible
}

.cdk-overlay-backdrop-noop-animation {
  transition: none
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: $z-indx-cdk;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll
}
/* < copied from  '@angular/cdk/overlay-prebuilt.css' */

$dp-popup-brdr-clr: #fff;
$dp-popup-brdr-width: 5px;
$dp-popup-day-txt-clr: #848fa5;
$dp-popup-month-brdr-clr: #e0e5ec;
$dp-popup-cell-display:flex;
$dp-popup-cell-gap:.5rem;

//make 100% width for use in forms
dp-date-picker{
    width:100%;
    position:relative;
    top:1px; //aligns with textbox in form

}

.cdk-overlay-pane {
  .dp {
    &-picker-input {
    }

    &-popup {
      border-left: solid $dp-popup-brdr-width $dp-popup-brdr-clr;
      border-right: solid $dp-popup-brdr-width $dp-popup-brdr-clr;
      border-bottom: solid $dp-popup-brdr-width $dp-popup-brdr-clr;
      box-shadow: 3px 3px 9px 0px rgb(0 0 0 / 15%)
    }

    &-calendar {
      &-nav {
        &-container {
          border: none;
          border-bottom: solid 1px $dp-popup-month-brdr-clr;
          padding: 1.25rem;

          button {
            color: $clr-tertiary-3;
            background-color: transparent;
            border: none;
            border-bottom: solid 1px transparent;
            width: auto;
            padding: .25rem;
            padding-left: 0;
            font-weight: bold;
            text-transform: uppercase;

            &.dp-nav-header-btn {
              font-size: .9rem;
              padding-bottom: 0;
            }

            &:hover {
              border-bottom: solid 1px $clr-tertiary-3;
            }
          }

          .dp-nav-btns-container {
            //right of month "<" & ">"
            display: inline-flex;
            gap: 1rem;

            button {
              padding: .25rem;
              display: flex;
              align-items: center;
              justify-content: center;

              &:hover {
                border-bottom: solid 1px $clr-tertiary-3;
              }
            }
          }
        }
      }
    }

    &-calendar-wrapper {
      border: none; //border around calendar
      padding-top: .5rem;

      .dp-calendar-weekday {
        border: none; //border to right of Sun, Mon, etc.
      }

      button {
        //day of the month cells
        border: none;
        background-color: transparent;
        color: $clr-black;
        font-size: .9rem;
        font-weight: bold;
        transition: background-color $trns-time $trns-function, color $trns-time $trns-function;

        &:hover {
          background-color: $clr-tertiary-3;
          color: $clr-white;
        }
      }
    }

    &-calendar-week {
      //spread out day select cells
      display: $dp-popup-cell-display;
      gap: $dp-popup-cell-gap;
    }

    &-weekdays {
      //Su, Mo, Tu, etc.
      color: $dp-popup-day-txt-clr;
      //spread out Su, Mo, etc. cells
      display: $dp-popup-cell-display;
      gap: $dp-popup-cell-gap;
    }
  }
}
.dp-picker-input {
  //calendar icon with #999 file to match placeholder color
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyBpZD0idXVpZC1mOTY4MjI5Ny1lYmJiLTQ0YjEtODUyMS01ZjhiMmY4MWVmOGUiIGRhdGEtbmFtZT0iQ2FsZW5kYXIiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmlld0JveD0iMCAwIDE2IDE2Ij4KICA8cGF0aCBkPSJNMTIuODU2NjksMi41NzA1aC0xLjg1NjY5VjFoLTF2MS41NzA1SDZWMWgtMXYxLjU3MDVoLTEuODU2NjljLTEuMTc4ODMsMC0yLjE0MzMxLC45NjQ0OC0yLjE0MzMxLDIuMTQzMzFWMTIuOTc2ODFjMCwuNDQyMDIsLjEzNTU2LC44NTM5NCwuMzY3MzEsMS4xOTYxNywuMzg2MTcsLjU3MDM3LDEuMDM5MjUsLjk0NzE0LDEuNzc2LC45NDcxNEgxMi44NTY2OWMuNzM2NzYsMCwxLjM4OTc3LS4zNzY3NywxLjc3Ni0uOTQ3MTQsLjIzMTY5LS4zNDIyMiwuMzY3MzEtLjc1NDE1LC4zNjczMS0xLjE5NjE3VjQuNzEzODFjMC0xLjE3ODgzLS45NjQ0OC0yLjE0MzMxLTIuMTQzMzEtMi4xNDMzMVpNMy4xNDMzMSwzLjU3MDVoMS44NTY2OXYuOTI5NWgxdi0uOTI5NWg0di45Mjk1aDF2LS45Mjk1aDEuODU2NjljLjYzMDQzLDAsMS4xNDMzMSwuNTEyODgsMS4xNDMzMSwxLjE0MzMxdjEuMjg2MTlIMnYtMS4yODYxOWMwLS42MzA0MywuNTEyODgtMS4xNDMzMSwxLjE0MzMxLTEuMTQzMzFaTTEyLjg1NjY5LDE0LjEyMDEySDMuMTQzMzFjLS42MzA0MywwLTEuMTQzMzEtLjUxMjk0LTEuMTQzMzEtMS4xNDMzMVY3SDE0djUuOTc2ODFjMCwuNjMwMzctLjUxMjg4LDEuMTQzMzEtMS4xNDMzMSwxLjE0MzMxWiIgZmlsbD0iIzk5OTk5OSIvPgo8L3N2Zz4=");
  background-repeat: no-repeat;
  background-size: rem(20px);
  background-position-x: 99%;
  background-position-y: 45%;
}
