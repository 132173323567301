@import '../SCSS/variables.scss';

%use {
  &-disabled {
    opacity: $dsbl-opacity;
    cursor: $dsbl-cursor;
  }
}

$icons: (
    'icon-save',
    'icon-trash-can',
    'icon-send-email',
    'icon-cancel',
    'icon-history',
    'icon-edit',
    'icon-calendar',
    'icon-circle-save',
    'icon-circle-trash-can',
    'icon-circle-send-email',
    'icon-circle-cancel',
    'icon-circle-history',
    'icon-circle-edit',
    'icon-circle-plus',
    'icon-circle-minus',
    'icon-refresh',
    'icon-info',
);

@each $icon in $icons {
  .#{$icon} {

    &--black {
      use {
        fill: $clr-black !important;
      }
    }

    &--white {
      use {
        fill: $clr-white !important;
      }
    }

    &--blue {
      use {
        fill: $clr-primary !important;
      }
    }

    &--gray {
      use {
        fill: $clr-tertiary-2 !important;
      }
    }

    &--green {
      use {
        fill: $clr-success !important;
      }
    }

    &--red {
      use {
        fill: $clr-red !important;
      }
    }
  }
}

svg:disabled {
  use {
    opacity: $dsbl-opacity;
    cursor: $dsbl-cursor;
  }
}
