@import '../SCSS/variables.scss';

.popperSpecialStyle {
  background-color: $clr-white;
  z-index: 9;
  color:darken($clr-primary, 10%);
  font-size: .9rem;
  
}
/* theme overrides */
.ngxp__container {
  border: solid 1px $clr-primary !important;
  box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.5)!important;
  //box-shadow: 3px 3px 5px 0px rgba(0,0,0,0.2) !important;
}

.tooltip-wrapper {
  display: inline-flex;
  align-items: center;
  margin-right: 0.5rem;
}
