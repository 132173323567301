.divider{
	&-h{
		display:block;
		&.primary{
			background-color: $clr-primary;
			color: $clr-white;
			text-align:center;
		}
		&.secondary{
			background-color:$clr-secondary;
			color:$clr-black;
			text-align:center;
		}
		&.tertiary-2{
			background-color: $clr-tertiary-2;
			color: $clr-white;
			text-align:center;
		}
	}
}