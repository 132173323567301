@import '../SCSS/variables.scss';
@import '../SCSS/mixins.scss';



input[type=radio] {
  appearance: none;
  display: grid;
  align-items: center;
  justify-items: center;
  grid-template-areas: "a";

  &:before {
    content: "";
    display: inline-block;
    height: 1rem;
    width: 1rem;
    background-color: $chk-rd-bg-clr;
    border: solid $chk-rd-brdr-wdth $chk-rd-brdr-clr;
    line-height: 1rem;
    vertical-align: text-top;
    border-radius: .6rem;
    cursor: pointer;
    grid-area: a;
  }

  &:checked {
    &:before {
      border-color: $chk-rd-chkd-brdr-clr;
    }

    &:after {
      content: "";
      color: $chk-rd-chkd-chk-clr;
      background-color: $chk-rd-chkd-bg-clr;
      border-color: $chk-rd-chkd-brdr-clr;
      display: inline-block;
      height: .5rem;
      width: .5rem;
      border-radius: .3rem;
      text-align: center;
      cursor: pointer;
      grid-area: a;
    }
  }

  &:disabled {
    &:before {
      opacity: $dsbl-opacity;
      cursor: $dsbl-cursor;
    }
  }
}
