@import '../SCSS/variables.scss';

.default-container {
  background-color: $clr-white;
  border-radius: .25rem;
  border: solid 1px $dc-grey-border;
  padding: $pad-unit;

  &.max-width {
    max-width: $max-width;
    margin:auto;
  }
  &.max-width-login {
    max-width: $max-width-login;
    margin:auto;
  }
}
