@import '../SCSS/variables.scss';
@import './functions.scss';
@import '../SCSS/mixins.scss';
$ng-select-highlight-bg: #e6f2fc;
:host {
  width: 100%;
}

ng-select {
  border-radius: $input-brdr-radius;

  &.ng-select-focused {
    /* mimics small black border, can't @extend a sub class!  */
    outline: $focus-thickness * 1px solid $clr-black;
    outline-offset: 2px;
  }
}





/* copied from node_modules/@ng-select/ng-select/themes/default.theme.css > */

/**************************************/
/* contains mods from variables.scss */
/**************************************/

.ng-select.ng-select-opened > .ng-select-container {
  background: #fff;
  border-color: #b3b3b3 #ccc #d9d9d9
}

.ng-select.ng-select-opened > .ng-select-container:hover {
  box-shadow: none
}

.ng-select.ng-select-opened > .ng-select-container .ng-arrow {
  top: rem(-2px);
  border-color: transparent transparent #999;
  border-width: 0 rem(5px) rem(5px)
}

.ng-select.ng-select-opened > .ng-select-container .ng-arrow:hover {
  border-color: transparent transparent #333
}

.ng-select.ng-select-opened.ng-select-top > .ng-select-container {
  border-top-right-radius: 0;
  border-top-left-radius: 0
}

.ng-select.ng-select-opened.ng-select-right > .ng-select-container {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0
}

.ng-select.ng-select-opened.ng-select-bottom > .ng-select-container {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0
}

.ng-select.ng-select-opened.ng-select-left > .ng-select-container {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0
}

.ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
  border-color: #007eff;
  box-shadow: inset 0 1px 1px rgba(0,0,0,0.075),0 0 0 3px rgba(0,126,255,0.1)
}

.ng-select.ng-select-disabled > .ng-select-container {
  background-color: #f9f9f9
}

.ng-select .ng-has-value .ng-placeholder {
  display: none;
}

.ng-select .ng-select-container {
  background-color: $input-bg-clr;
  border-radius: $input-brdr-radius;
  border: 1px solid $input-brdr-clr;
  align-items: center;
  padding: $input-padding;
}

.ng-select .ng-select-container:hover {
  box-shadow: 0 1px 0 rgba(0,0,0,0.06)
}

.ng-select .ng-select-container .ng-value-container {
  align-items: center;
  padding-left: $input-padding;
}

[dir="rtl"] .ng-select .ng-select-container .ng-value-container {
  padding-right: 10px;
  padding-left: 0
}

.ng-select .ng-select-container .ng-value-container .ng-placeholder {
  text-transform: none;
  font-weight: normal;
  padding: calc($input-padding * .5);
  padding-left: 0;
  color: $input-placeholder-clr;
}

.ng-select.ng-select-single .ng-select-container {
}

.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  top: 5px;
  left: 0;
  padding-left: rem(10px);
  padding-right: rem(50px)
}

[dir="rtl"] .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  padding-right: rem(10px);
  padding-left: rem(50px);
}

.ng-select.ng-select-multiple.ng-select-disabled > .ng-select-container .ng-value-container .ng-value {
  background-color: #f9f9f9;
  border: 1px solid #e6e6e6
}

.ng-select.ng-select-multiple.ng-select-disabled > .ng-select-container .ng-value-container .ng-value .ng-value-label {
  padding: 0 rem(5px);
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container {
  /*padding-top: 5px;
  padding-left: 7px
  */
  padding: calc($input-padding * .5);
  gap:.25rem;
}

[dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container {
  padding-right: rem(7px);
  padding-left: 0
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  font-size: .9em;
  /*margin-bottom: 5px;*/
  color: $clr-primary;
  background-color: $clr-white;
  border-radius: 2px;
  margin-right: .5rem;
  border: 1px solid transparentize($clr-primary, .7);
}

[dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  margin-right: 0;
  margin-left: rem(5px);
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value.ng-value-disabled {
  background-color: #f9f9f9
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value.ng-value-disabled .ng-value-label {
  padding-left: rem(5px);
}

[dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value.ng-value-disabled .ng-value-label {
  padding-left: 0;
  padding-right: rem(5px);
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-label {
  display: inline-block;
  padding: rem(1px) rem(5px);
  font-weight:normal;
  color:$clr-primary;
  text-transform:none;
  letter-spacing:.5px;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon {
  display: inline-block;
  padding: rem(1px) rem(5px);
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left {
  transition: background-color $trns-time $trns-function, color $trns-time $trns-function;
  background-color: transparentize($clr-primary, .8);
  border-right: 1px solid transparentize($clr-primary, .6);
  color: $clr-primary;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon:hover {
  background-color: $clr-primary;
  color: $clr-white;
}

[dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left {
  border-left: 1px solid #b8dbff;
  border-right: none
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.right {
  border-left: 1px solid #b8dbff
}

[dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.right {
  border-left: 0;
  border-right: 1px solid #b8dbff
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input {
  /*
  padding: 0 0 3px 3px*/
}

[dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input {
  padding: 0 rem(3px) rem(3px) 0
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input > input {
  color: #000
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
  top: 5px;
  padding-bottom: rem(5px);
  padding-left: rem(3px);
}

[dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
  padding-right: rem(3px);
  padding-left: 0
}

.ng-select .ng-clear-wrapper {
  color: $clr-primary;
}

.ng-select .ng-clear-wrapper:hover .ng-clear {
  color: $clr-primary;
}

.ng-select .ng-spinner-zone {
  padding: rem(5px) rem(5px) 0 0
}

[dir="rtl"] .ng-select .ng-spinner-zone {
  padding: rem(5px) 0 0 rem(5px);
}

.ng-select .ng-arrow-wrapper {
  width: rem(25px);
  /*padding-right: 5px;*/
  top:rem(1px); /* better aligns triangle with x */
}

[dir="rtl"] .ng-select .ng-arrow-wrapper {
  padding-left: rem(5px);
  padding-right: 0
}

.ng-select .ng-arrow-wrapper:hover .ng-arrow {
  border-top-color: #666
}

.ng-select .ng-arrow-wrapper .ng-arrow {
  border-color: $clr-primary transparent transparent;
  border-style: solid;
  border-width: rem(5px) rem(5px) rem(2.5px);
  top:rem(1px); /* better alignment with x */
}

.ng-dropdown-panel {
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 1px 0 rgba(0,0,0,0.06);
  left: 0
}

.ng-dropdown-panel.ng-select-top {
  bottom: 100%;
  border-top-right-radius: rem(4px);
  border-top-left-radius: rem(4px);
  border-bottom-color: #e6e6e6;
  margin-bottom: rem(-1px);

}

.ng-dropdown-panel.ng-select-top .ng-dropdown-panel-items .ng-option:first-child {
  border-top-right-radius: rem(4px);
  border-top-left-radius: rem(4px);
}

.ng-dropdown-panel.ng-select-right {
  left: 100%;
  top: 0;
  border-top-right-radius: rem(4px);
  border-bottom-right-radius: rem(4px);
  border-bottom-left-radius: rem(4px);
  border-bottom-color: #e6e6e6;
  margin-bottom: rem(-1px);
}

.ng-dropdown-panel.ng-select-right .ng-dropdown-panel-items .ng-option:first-child {
  border-top-right-radius: rem(4px);
}

.ng-dropdown-panel.ng-select-bottom {
  top: 100%;
  border-bottom-right-radius: rem(4px);
  border-bottom-left-radius: rem(4px);
  border-top-color: #e6e6e6;
  margin-top: rem(-1px);
}

.ng-dropdown-panel.ng-select-bottom .ng-dropdown-panel-items .ng-option:last-child {
  border-bottom-right-radius: rem(4px);
  border-bottom-left-radius: rem(4px);
}

.ng-dropdown-panel.ng-select-left {
  left: -100%;
  top: 0;
  border-top-left-radius: rem(4px);
  border-bottom-right-radius: rem(4px);
  border-bottom-left-radius: rem(4px);
  border-bottom-color: #e6e6e6;
  margin-bottom: rem(-1px);
}

.ng-dropdown-panel.ng-select-left .ng-dropdown-panel-items .ng-option:first-child {
  border-top-left-radius: rem(4px);
}

.ng-dropdown-panel .ng-dropdown-header {
  border-bottom: 1px solid #ccc;
  padding: rem(5px) rem(7px);
}

.ng-dropdown-panel .ng-dropdown-footer {
  border-top: 1px solid #ccc;
  padding: rem(5px) rem(7px);
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup {
  user-select: none;
  padding: rem(8px) rem(10px);
  font-weight: 500;
  color: rgba(0,0,0,0.54);
  cursor: pointer
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-disabled {
  cursor: default
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-marked {
  background-color: #f5faff
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-selected, .ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-selected.ng-option-marked {
  color: rgba(0,0,0,0.54);
  background-color: #ebf5ff;
  font-weight: 600
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  background-color: #fff;
  color: rgba(0,0,0,0.87);
  padding: rem(8px) rem(10px);
  text-transform: none;
  font-weight:normal;
  font-size:.9rem;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected, .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
  color: #333;
  background-color: $ng-select-highlight-bg;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected .ng-option-label, .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked .ng-option-label {
  font-weight: normal;
  text-transform:none;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  background-color: $ng-select-highlight-bg;
  color: #333
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-disabled {
  color: #ccc
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-child {
  padding-left: rem(22px);
}

[dir="rtl"] .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-child {
  padding-right: rem(22px);
  padding-left: 0
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-tag-label {
  font-size: 80%;
  font-weight: 400;
  padding-right: rem(5px);
}

[dir="rtl"] .ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-tag-label {
  padding-left: rem(5px);
  padding-right: 0
}

[dir="rtl"] .ng-dropdown-panel {
  direction: rtl;
  text-align: right;
}

/* < copied from node_modules/@ng-select/ng-select/themes/default.theme.css */

/* ADDED > */
.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value{
    flex-grow:1;
    text-transform:none;
    font-weight:normal;
}

.ng-select .ng-spinner-loader {
  border-top: 2px solid transparentize($clr-primary, .8) !important;
  border-right: 2px solid transparentize($clr-primary, .8) !important;
  border-bottom: 2px solid transparentize($clr-primary, .8) !important;
  border-left: 2px solid $clr-primary !important;
}

.ng-select.ng-invalid.ng-touched {
	border: 1px solid $clr-red !important;
}


/* Inside Ag Grid */
.ag-popup-editor {
  ng-select {
    &.ng-select-focused {
      outline:none;
    }
  }
}
/* CAS Number */
.cas-number .ng-dropdown-panel {
  width: 600px;

  .ng-dropdown-panel-items {
    .ng-option{
        white-space:normal;
    }
  }
}

/* operator ng-select in reports */
.ng-select.operator .ng-select-container .ng-value-container{
    max-width:132px;
}
@include xl {
  .ng-select.operator .ng-select-container .ng-value-container {
    max-width:none;
  }
}
  /* < ADDED */



