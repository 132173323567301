/* tabs > */
//padding t/b, l/r
$tabs-padding:(
		.5,
		2
);

ul{
	&.tabs{
		list-style-type:none;
		cursor:pointer;
		display:flex;
        margin:0;
        padding:0;
		gap:.5rem;
		position:relative;
		bottom:-1px;
		a{
			border-top-left-radius:.25rem;
			border-top-right-radius:.25rem;
			@include font-size('ex-sml');
			color: $clr-white;
			background-color: $clr-tertiary-2;
			padding: nth($tabs-padding, 1) * 1rem nth($tabs-padding, 2) * 1rem;
			font-weight:bold;
            text-decoration:none;
			display:inline-block;
			border-top:solid 1px $dc-grey-border;
			border-left:solid 1px $dc-grey-border;
			border-right: solid 1px $dc-grey-border;
			transition: background-color .25s ease-in;
            text-transform:uppercase;
			&:hover{
				background-color: darken($clr-tertiary-2, 25%);
			}
			&.active{
				
				color:$clr-tertiary-3;
				background-color: $clr-white;
				cursor:not-allowed;
				border-top:solid 1px $dc-grey-border;
				border-left:solid 1px $dc-grey-border;
				border-right:solid 1px $dc-grey-border;
				&:hover{
					background-color:$clr-white;
				}
			}
		}
	}
}
