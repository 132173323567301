/* COLORS > */


.clr-white{
	color:$clr-white;
}
.clr-black{
	color:$clr-black;
}
.clr-red{
	color:$clr-red;
}
.clr-tertiary{
	color:$clr-tertiary;
}
.clr-tertiary-2{
	color:$clr-tertiary-2;
}
.clr-tertiary-3{
	color:$clr-tertiary-3;
}



/* PRIMARY */
.clr-p{
	color:$clr-primary;
}

/* CLR lighten */
@each $percentage in $clr-percentages {
	$percent : percentage(calc($percentage/100));

	.clr-p-l {
		&-#{$percentage} {
			color: lighten($clr-primary, $percent);
		}
	}
}
/* darken */
@each $percentage in $clr-percentages {
	$percent : percentage(calc($percentage/100));

	.clr-p-d {
		&-#{$percentage} {
			color: darken($clr-primary, $percent);
		}
	}
}



/* Secondary */

.clr-s{
	color:$clr-secondary;
}

/* lighten */
@each $percentage in $clr-percentages {
  $percent: percentage(calc($percentage/100));

	.clr-s-l {
		&-#{$percentage} {
			color: lighten($clr-secondary, $percent);
		}
	}
}
/* darken */
@each $percentage in $clr-percentages {
  $percent: percentage(calc($percentage/100));

	.clr-s-d {
		&-#{$percentage} {
			color: darken($clr-secondary, $percent);
		}
	}
}


/* Tertiary */
.clr-t{
	color:$clr-tertiary;
}


/* lighten */
@each $percentage in $clr-percentages {
  $percent: percentage(calc($percentage/100));

	.clr-t-l {
		&-#{$percentage} {
			color: lighten($clr-tertiary, $percent);
		}
	}
}
/* darken */
@each $percentage in $clr-percentages {
  $percent: percentage(calc($percentage/100));

	.clr-t-d {
		&-#{$percentage} {
			color: darken($clr-tertiary, $percent);
		}
	}
}

/* < COLORS */
