@import './functions.scss';

/* COLORS */
// standard
$clr-white: #fff;
$clr-black: #000;
$clr-red: #c40000;
$clr-success: #029810;
$clr-warning: #fd8b05;
$clr-caution: #ffcf00;
$clr-info: #f9e690;
$clr-error: #ff0000;
$clr-default: #555;
// app specific
$clr-primary: #004479;
$clr-secondary: #99C63E;
$clr-tertiary: #263445;
$clr-tertiary-2: #8392a7;
$clr-tertiary-3: #0093c8;


$pad-unit: 1rem;
$margin-unit:1rem;
$btn-gap: .5rem;
$mobile-gap: 1rem;

/* FONTS */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');
$font-family: 'Roboto', sans-serif;

//sizes - tiny, ex-small, small, medium, large, ex-large
$font-sizes:(
	.6,
	.7,
	.8,
	1,
	1.1,
	1.2
);


/* BREAKPOINTS */
$screen-sm-min:rem(530px);
$screen-md-min:rem(768px);
$screen-lg-min:rem(1040px);
$screen-xl-min:rem(1550px); 

/* HEADER FOOTER */
$header-footer-container-padding:1rem;
$header-gray-text: darken($clr-tertiary-2, 10%);

/* breadcrumbs */
$breadcrumbs-container-padding:.5rem .75rem;



/* HEADINGS <h1> */
// heading sizes in rem h1-h5 (0-small bp)
$heading-values:(
	1.5,
	1.35,
	1.15,
	.9,
	.8
);

//heading sizes (medium bp+ multipler added to $heading-values)
$heading-md-mulitplier:1.1;

/* MARGINS/PADDING */
//margin/padding values (dash is equal to decimal point. decimal point is not allowed in class name)
$mrgn-pd-values :(
	"0",
	"-25",
	"-50",
	"-75",
	"1",
	"1-25",
	"1-5",
	"1-75",
	"2",
	"2-5",
	"3"
);

//do not edit trbl - top, right, bottom, left prefixes
$mrgn-pd-trbl :(
	't',
	'r',
	'b',
	'l'
);




//color/bg varient percentages
$clr-percentages:(
	10,
	20,
	30,
	40,
	50,
	60,
	65,
	70
);

/* BUTTON */
$btn-border-radius: .25; //rem

/* button PADDING: small, medium, large */
$btn-padding :(
	.5,
	.75,
	1
);

/* button FONT SIZE: small, medium, large */
$btn-font-sizes :(
	.8,
	.9,
	1
);

/* button background CLASSES: primary, secondary, tertiary */
$btn-bg-class :(
	'.bg-p',
	'.bg-s',
	'.bg-white',
);

/* button text color CLASSES: primary, secondary, tertiary */
$btn-clr-class :(
	'.clr-white',
	'.clr-white',
	'.clr-p',
);


/* CSS GRID */
$grid-columns:(
	2,
	3,
	4,
	5
);

$grid-column-widths:(
	auto,
	1fr
);



/* BORDERS */
$bdr-wwdth-px:1;
$bdr-radius-rem:.25;
$bdr-style:solid;

/* TRANSITIONS */
$trns-time: .2s;
$trns-function: ease-in;

/* FOCUS */
$focus-thickness: 2;
$focus-offset:4;
//dark & light focus colors
$focus-colors:(
		$clr-black,
		$clr-white
);
//focus offset sml, md, lrg (pixels)
$focus-offsets:(
	2,
	4,
	8
);

//default container 
$dc-grey-border: lighten($clr-tertiary-2, 25%);

//a (link)
$a-clr: saturate($clr-tertiary-3, 100%);

//max width
$max-width:rem(900px);
$max-width-form:rem(800px);

// max width for Login
$max-width-login: rem(400px);


/* disabled */
$dsbl-opacity: .6;
$dsbl-cursor: not-allowed;

/* checkbox / radio */
$chk-rd-bg-clr: #f7f8fa;
$chk-rd-brdr-clr: #cdd2db;
$chk-rd-brdr-wdth: 1px;
$chk-rd-brdr-radius: 3px;
$chk-rd-chkd-brdr-clr: $clr-tertiary-3;
$chk-rd-chkd-bg-clr: $clr-tertiary-3;
$chk-rd-chkd-chk-clr: $clr-white;

/* input[type=text]*/
$input-bg-clr: lighten($clr-tertiary-2, 40%);
$input-brdr-clr: lighten($clr-tertiary-2, 25%);
$input-brdr-radius: .25rem;
$input-padding:.25rem;
$input-placeholder-clr: #999999;
$input-label-m-t:.2rem;

/* Data Grid */
$dg-btn-height:rem(25px);


/* z-indexes */
$z-indx-main-nav: 2;
$z-indx-cdk: 150;
/*
  UI-Library z-indexes

  loading:100;
  modal:101;
  toast:200;

*/
