@import '../SCSS/variables.scss';
@import '../SCSS/mixins.scss';

input[type=text], textarea {
  border-radius: $input-brdr-radius;
  background-color: $input-bg-clr;
  border: solid 1px $input-brdr-clr;
  padding: calc($input-padding * 1.5);
  box-sizing: border-box;
  @extend .focus-dark-sml;
  width: 100%;

  &:disabled {
    opacity: $dsbl-opacity;
    cursor: $dsbl-cursor;
  }
}
