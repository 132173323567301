@import '../SCSS/variables.scss';
@import '../SCSS/mixins.scss';
@import '../SCSS/functions.scss';

input:not([type=checkbox]):not([type=radio]):not([type=submit]):not([type=button]):not([type=text]), [type=search], select{
	width:100%;
	border-radius:.25rem;
	background-color:$input-bg-clr;
	border:solid 1px $input-brdr-clr;
	padding:.25rem;
	box-sizing:border-box;
 	@extend .focus-dark-sml;

	&.width-auto{
		width: auto;
	}
 
}


input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  height: rem(16px);
}

   

.ag-cell {
  input:not([type=checkbox]):not([type=radio]):not([type=submit]):not([type=button]):not([type=text]), [type=search], select {
    &:focus {
      //cover over validate when red border is displaying
      border: solid 1px #2196f3!important;
      outline:none;
    }
  }
}

  ::placeholder {
    color: $input-placeholder-clr;
    @include font-size('sml');
  }

  input.ng-invalid.ng-touched {
    border: 1px solid $clr-red !important;

    &[type=radio] {
      border-radius: .6rem;
    }

    &[type=checkbox] {
      border-radius: $chk-rd-brdr-radius;
    }
  }
